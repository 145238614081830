import { OrderStatus } from '@futureecom/futureecom-js/dist/services/order-service'
import type { FilterDefinition } from '@/types/FilterDefinitions'
import type { OrderFilter } from '@futureecom/futureecom-js/dist/services/order-service'

export const orderFilters: FilterDefinition<OrderFilter>[] = [
  { type: 'text', code: 'customer.firstname', label: 'First name' },
  { type: 'text', code: 'customer.lastname', label: 'Last name' },
  {
    type: 'select-multiple',
    code: 'status',
    label: 'Status',
    options: [
      { text: 'placed', value: OrderStatus.PLACED },
      { text: 'approved', value: OrderStatus.APPROVED },
      { text: 'processing', value: OrderStatus.PROCESSING },
      { text: 'on_hold', value: OrderStatus.ON_HOLD },
      { text: 'refunded', value: OrderStatus.REFUNDED },
      { text: 'shipped', value: OrderStatus.SHIPPED },
      { text: 'canceled', value: OrderStatus.CANCELED },
      { text: 'completed', value: OrderStatus.COMPLETED }
    ]
  },
  { type: 'money-range', code: 'summary.total_due.amount', label: 'Price Amount' },
  { type: 'text', code: 'number', label: 'Number' },
  { type: 'text', code: 'requested_delivery', label: 'Requested delivery' },
  { type: 'text', code: 'created_by.name', label: 'Created By' },
  {
    type: 'select-multiple',
    code: 'created_by.from',
    label: 'Created From',
    options: [
      { text: 'Storefront', value: 'storefront' },
      { text: 'Point of Sale', value: 'pos' },
      { text: 'Console', value: 'console' },
      { text: 'API', value: 'api' }
    ]
  },
  { type: 'date', code: 'created_at', label: 'Created at' }
]
